import React, { useState, useReducer } from 'react'
import { useMachine } from '@xstate/react'
import { useTranslation } from 'react-i18next'
import { machine } from '#app/machines/machine'
import { Card } from './ui/card'
import { Button } from './ui/button'
import { Label } from './ui/label'
import { Input } from './ui/input'
import { Slider } from './ui/slider'
import {
  Building2Icon,
  BuildingIcon,
  HomeIcon,
  LandPlotIcon,
  Loader2,
  SquareCheck,
} from 'lucide-react'
import { fromPromise } from 'xstate'
import axios from 'axios'
import ProgressNavigation from './progress-navigation'
import MultiChoiceButton from './multi-choice-button'
import { ToggleGroup, ToggleGroupItem } from './ui/toggle-group'

export function LeadForm({
  userId,
  textColor,
  backgroundColor,
}: {
  userId: string
  textColor: string
  backgroundColor: string
}) {
  const [state, send] = useMachine(
    machine.provide({
      actions: {},
      actors: {
        submitFormData: fromPromise(
          async ({ input }: { input: { data: Record<string, string> } }) => {
            return axios.post('/resources/leads', {
              userId,
              data: { ...input.data },
            })
          },
        ),
      },
    }),
  )

  const handleNext = (data: Record<string, any>) => {
    send({ type: 'NEXT', data })
  }

  const renderCurrentStep = () => {
    switch (state.value) {
      case 'property_type':
        return (
          <PropertyType
            textColor={textColor}
            backgroundColor={backgroundColor}
            onPropertyTypeSelect={(
              type:
                | 'PLOT_SELECTED'
                | 'HOUSE_SELECTED'
                | 'APARTMENT_SELECTED'
                | 'COMMERCIAL_SELECTED',
            ) => send({ type })}
          />
        )
      case 'size':
        return (
          <PropertySize
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'room_size':
        return (
          <RoomSize
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'build_year':
        return (
          <BuildYear
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'current_usage':
        return (
          <CurrentUsage
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'quality':
        return (
          <Condition
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'transaction':
        return (
          <TransactionType
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'timeline':
        return (
          <Timeline
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'occupation_status':
        return (
          <OccupationStatus
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'postal_code':
        return (
          <PostalCode
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'personal_details':
        return (
          <PersonalDetails
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'phone_number':
        return (
          <PhoneNumber
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={handleNext}
          />
        )
      case 'email':
        return (
          <EmailInput
            textColor={textColor}
            backgroundColor={backgroundColor}
            next={(data: Record<string, any>) => {
              handleNext(data)
            }}
          />
        )
      case 'submitting':
        return <SubmittingState textColor={textColor} backgroundColor={backgroundColor} />
      case 'submission_error':
        return (
          <SubmissionError
            textColor={textColor}
            backgroundColor={backgroundColor}
            onRetry={() => send({ type: 'NEXT' })}
          />
        )
      case 'data_submitted':
        return <ThankYou textColor={textColor} backgroundColor={backgroundColor} />
      default:
        return null
    }
  }
  return (
    <div className="mx-auto flex w-full max-w-md items-center justify-center rounded-md">
      <div
        style={{ color: textColor }}
        className="relative mx-auto flex h-[394px] w-[368px] items-center justify-center rounded-md bg-background p-6 shadow-md">
        <div className="mx-auto h-full w-full bg-background">
          <ProgressNavigation
            backgroundColor={backgroundColor}
            state={state}
            send={send}
          />
          {renderCurrentStep()}
        </div>
      </div>
    </div>
  )
}
function SubmittingState({ textColor }: { backgroundColor: string; textColor: string }) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center">
      <Loader2 className="animate-spin text-[#333333]" />
      <p className="mt-4 text-[#333333]"> {t('submitting_data')}</p>
    </div>
  )
}

function SubmissionError({
  onRetry,
  textColor,
  backgroundColor,
}: {
  onRetry: () => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center text-[#333333]">
      <p>{t('submission_error')}</p>
      <Button
        style={{ color: textColor, backgroundColor: backgroundColor }}
        onClick={onRetry}
        className="h-14 w-full rounded-2xl text-center">
        {t('retry_submission')}
      </Button>
    </div>
  )
}

function PropertyType({
  onPropertyTypeSelect,
  textColor,
  backgroundColor,
}: {
  onPropertyTypeSelect: (
    type:
      | 'PLOT_SELECTED'
      | 'HOUSE_SELECTED'
      | 'APARTMENT_SELECTED'
      | 'COMMERCIAL_SELECTED',
  ) => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="mb-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_type')}
      </h2>
      <div className="grid grid-cols-2 justify-center gap-2 bg-[#FDFDFD]">
        <Card
          onClick={() => onPropertyTypeSelect('PLOT_SELECTED')}
          style={{ color: backgroundColor }}
          className="flex max-h-28 max-w-40 cursor-pointer flex-col items-center justify-center rounded-2xl border border-[#DFE0E1] px-6 py-4 hover:shadow-lg">
          <LandPlotIcon className="mb-2 h-10 w-10" />
          <div className="font-bold text-[#333333]">{t('lead_widget_land')}</div>
        </Card>
        <Card
          onClick={() => onPropertyTypeSelect('HOUSE_SELECTED')}
          style={{ color: backgroundColor }}
          className="flex max-h-28 max-w-40 cursor-pointer flex-col items-center justify-center rounded-2xl border border-[#DFE0E1] px-6 py-4 hover:shadow-lg">
          <HomeIcon className="mb-2 h-10 w-10" />
          <div className="font-bold text-[#333333]">{t('lead_widget_house')}</div>
        </Card>
        <Card
          onClick={() => onPropertyTypeSelect('APARTMENT_SELECTED')}
          style={{ color: backgroundColor }}
          className="flex max-h-28 max-w-40 cursor-pointer flex-col items-center justify-center rounded-2xl border border-[#DFE0E1] px-6 py-4 hover:shadow-lg">
          <BuildingIcon className="mb-2 h-10 w-10" />
          <div className="font-bold text-[#333333]">{t('lead_widget_apartment')}</div>
        </Card>
        <Card
          onClick={() => onPropertyTypeSelect('COMMERCIAL_SELECTED')}
          style={{ color: backgroundColor }}
          className="flex max-h-28 max-w-40 cursor-pointer flex-col items-center justify-center rounded-2xl border border-[#DFE0E1] px-6 py-4 hover:shadow-lg">
          <Building2Icon className="mb-2 h-10 w-10" />
          <div className="font-bold text-[#333333]">
            {t('lead_widget_commercial_space')}
          </div>
        </Card>
      </div>
    </>
  )
}

function PropertySize({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const [size, setSize] = useState<string>('')
  const [unit, setUnit] = useState<'sqm' | 'sqft'>('sqm')
  const { t } = useTranslation()
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && size) {
      next({ size: `${size}` })
    }
  }
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_size')}
      </h2>
      <div className="flex h-72 flex-col">
        <div className="relative">
          <Input
            autoFocus={true}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            className="h-14 text-[#333333]"
            placeholder="150"
            value={size}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSize(e.target.value)}
          />
          <ToggleGroup
            type="single"
            onValueChange={(value) => setUnit(value as 'sqm' | 'sqft')}
            defaultValue="sqm"
            className="absolute right-4 top-2 rounded-lg border border-[#DFE0E1] bg-[#FDFDFD]">
            <ToggleGroupItem
              value="sqm"
              style={
                {
                  '--selected-bg': backgroundColor,
                } as React.CSSProperties
              }
              className={`flex-1 rounded-lg px-3 py-2 text-sm font-medium text-[#333333] hover:bg-[var(--selected-bg)] data-[state=on]:bg-[var(--selected-bg)] data-[state=on]:text-[#fdfdfd]`}>
              m²
            </ToggleGroupItem>
            <ToggleGroupItem
              value="sqft"
              style={
                {
                  '--selected-bg': backgroundColor,
                } as React.CSSProperties
              }
              className={`flex-1 rounded-lg px-3 py-2 text-sm font-medium text-[#333333] hover:bg-[var(--selected-bg)] data-[state=on]:bg-[var(--selected-bg)] data-[state=on]:text-[#fdfdfd]`}>
              ft²
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
        <div className="mt-auto pb-12">
          <Button
            style={{ color: textColor, backgroundColor: backgroundColor }}
            className="h-14 w-full rounded-2xl text-center"
            disabled={!size}
            onClick={() => next({ size, unit })}>
            {t('button_next')}
          </Button>
        </div>
      </div>
    </div>
  )
}

function RoomSize({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const [bedrooms, setBedrooms] = useState<number>(4)
  const { t } = useTranslation()
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-6 text-xl font-bold leading-5" style={{ color: textColor }}>
        {t('lead_widget_qs_bedroom')}
      </h2>
      <div className="space-y-4">
        <Label className="text-[#333333]" htmlFor="bedrooms">
          {t('label_slider')}
        </Label>
        <Slider
          step={1}
          min={0}
          max={10}
          value={[bedrooms]}
          onValueChange={(value: number[]) => setBedrooms(value[0])}
        />
        <div className="mt-2 flex justify-between text-[#333333]">
          <span>0</span>
          <span>10+</span>
        </div>
      </div>
      <div className="mt-auto pb-12">
        <Button
          style={{ color: textColor, backgroundColor: backgroundColor }}
          className="h-14 w-full rounded-2xl text-center"
          disabled={!bedrooms}
          onClick={() => next({ bedrooms: `${bedrooms}` })}>
          {t('button_next')}
        </Button>
      </div>
    </div>
  )
}

function BuildYear({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && buildYear) {
      next({ buildYear: `${buildYear}` })
    }
  }
  const { t } = useTranslation()
  const [buildYear, setBuildYear] = useState<number | undefined>()
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-6 text-xl font-semibold text-[#333333]">
        {t('lead_widget_qs_build_year')}
      </h2>
      <div className="space-y-4">
        <div>
          <Input
            autoFocus={true}
            className="h-14 text-[#333333]"
            placeholder="1990"
            inputMode="numeric"
            pattern="[0-9]*"
            type="number"
            onKeyDown={handleKeyDown}
            min={1880}
            value={buildYear}
            onChange={(e) => setBuildYear(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div className="mt-auto pb-12">
        <Button
          style={{ color: textColor, backgroundColor: backgroundColor }}
          className="h-14 w-full rounded-2xl text-center"
          disabled={!buildYear}
          onClick={() => next({ buildYear: `${buildYear}` })}>
          {t('button_next')}
        </Button>
      </div>
    </div>
  )
}

function CurrentUsage({
  next,
}: {
  next: (data: Record<string, any>) => void
  textColor?: string
  backgroundColor?: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_vacancy')}
      </h2>
      <div className="space-y-1">
        <MultiChoiceButton
          onClick={() =>
            next({ currentUsage: t('lead_widget_qs_property_vacancy_rented') })
          }>
          {t('lead_widget_qs_property_vacancy_rented')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() =>
            next({ currentUsage: t('lead_widget_qs_property_vacancy_vacant') })
          }>
          {t('lead_widget_qs_property_vacancy_vacant')}
        </MultiChoiceButton>
      </div>
    </>
  )
}

function Condition({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_condition')}
      </h2>
      <div className="space-y-1">
        <MultiChoiceButton
          onClick={() => next({ condition: t('lead_widget_conditon_new') })}>
          {t('lead_widget_conditon_new')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() =>
            next({ condition: t('lead_widget_conditon_renovation_needed') })
          }>
          {t('lead_widget_conditon_renovation_needed')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() => next({ condition: t('lead_widget_conditon_well_maintained') })}>
          {t('lead_widget_conditon_well_maintained')}
        </MultiChoiceButton>
      </div>
    </>
  )
}

function TransactionType({
  next,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_transaction')}
      </h2>
      <div className="space-y-1">
        <MultiChoiceButton onClick={() => next({ transaction: 'sell' })}>
          {t('lead_widget_qs_property_transaction_sale')}
        </MultiChoiceButton>
        <MultiChoiceButton onClick={() => next({ transaction: 'buy' })}>
          {t('lead_widget_qs_property_transaction_buy')}
        </MultiChoiceButton>
        <MultiChoiceButton onClick={() => next({ transaction: 'other' })}>
          {t('lead_widget_qs_property_transaction_rent')}
        </MultiChoiceButton>
      </div>
    </>
  )
}

function Timeline({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_property_timeframe')}
      </h2>
      <div className="space-y-1">
        <MultiChoiceButton
          onClick={() =>
            next({ timeline: t('lead_widget_qs_property_timeframe_3months') })
          }>
          {t('lead_widget_qs_property_timeframe_3months')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() =>
            next({ timeline: t('lead_widget_qs_property_timeframe_6months') })
          }>
          {t('lead_widget_qs_property_timeframe_6months')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() =>
            next({ timeline: t('lead_widget_qs_property_timeframe_6_12months') })
          }>
          {t('lead_widget_qs_property_timeframe_6_12months')}
        </MultiChoiceButton>
        <MultiChoiceButton
          onClick={() =>
            next({ timeline: t('lead_widget_qs_property_timeframe_12months_plus') })
          }>
          {t('lead_widget_qs_property_timeframe_12months_plus')}
        </MultiChoiceButton>
      </div>
    </>
  )
}

function OccupationStatus({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <h2 style={{ color: textColor }} className="mb-4 text-2xl font-bold">
        {t('lead_widget_qs_property_occupation')}
      </h2>
      <div className="space-y-4">
        <MultiChoiceButton onClick={() => next({ occupation: 'owned' })}>
          {t('lead_widget_qs_property_occupation_owner')}
        </MultiChoiceButton>
        <MultiChoiceButton onClick={() => next({ occupation: 'rented' })}>
          {t('lead_widget_qs_property_occupation_rented')}
        </MultiChoiceButton>
      </div>
    </>
  )
}

function PostalCode({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && postalCode) {
      next({ postalCode: `${postalCode}` })
    }
  }
  const [postalCode, setPostalCode] = useState<string>('')
  const { t } = useTranslation()
  return (
    <div className="flex h-full flex-col">
      <div>
        <h2 className="my-6 text-xl font-semibold leading-5 text-[#333333]">
          {t('lead_widget_qs_postalCode_heading')}
        </h2>
        <div className="grid gap-2">
          <Input
            autoFocus={true}
            id="zip"
            name="zip"
            className="h-14 text-[#333333]"
            type="number"
            inputMode="numeric"
            onKeyDown={handleKeyDown}
            pattern="[0-9]*"
            placeholder="10115"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-auto pb-12">
        <Button
          style={{ color: textColor, backgroundColor: backgroundColor }}
          className="h-14 w-full rounded-2xl text-center"
          disabled={!postalCode}
          onClick={() => next({ postalCode })}>
          {t('button_next')}
        </Button>
      </div>
    </div>
  )
}

type PersonalDetailsState = {
  title: string
  firstName: string
  lastName: string
}

type PersonalDetailsAction =
  | { type: 'setTitle'; payload: string }
  | { type: 'setFirstName'; payload: string }
  | { type: 'setLastName'; payload: string }

const initialPersonalDetailsState: PersonalDetailsState = {
  title: '',
  firstName: '',
  lastName: '',
}

function personalDetailsReducer(
  state: PersonalDetailsState,
  action: PersonalDetailsAction,
): PersonalDetailsState {
  switch (action.type) {
    case 'setTitle':
      return { ...state, title: action.payload }
    case 'setFirstName':
      return { ...state, firstName: action.payload }
    case 'setLastName':
      return { ...state, lastName: action.payload }
    default:
      return state
  }
}

function PersonalDetails({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: PersonalDetailsState) => void
  textColor: string
  backgroundColor: string
}) {
  const [state, dispatch] = useReducer(
    personalDetailsReducer,
    initialPersonalDetailsState,
  )
  const { t } = useTranslation()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && state.title && state.firstName && state.lastName) {
      next({ ...state })
    }
  }
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-4 text-xl font-semibold leading-5 text-[#333333]">
        {t('lead_widget_personal_info')}
      </h2>
      <div className="space-y-1">
        <ToggleGroup
          type="single"
          defaultValue="mr"
          style={{ '--selected-bg': backgroundColor } as React.CSSProperties}
          onValueChange={(value) => dispatch({ type: 'setTitle', payload: value })}
          className="flex h-14 w-full max-w-xs rounded-lg border border-[#DFE0E1] px-2">
          <ToggleGroupItem
            value="mr"
            className={`flex-1 rounded-lg px-3 py-2 text-sm font-medium text-[#333333] hover:bg-[var(--selected-bg)] data-[state=on]:bg-[var(--selected-bg)] data-[state=on]:text-[#fdfdfd]`}>
            {t('title_mr')}
          </ToggleGroupItem>
          <ToggleGroupItem
            value="mrs"
            className={`flex-1 rounded-lg px-3 py-2 text-sm font-medium text-[#333333] hover:bg-[var(--selected-bg)] data-[state=on]:bg-[var(--selected-bg)] data-[state=on]:text-[#fdfdfd]`}>
            {t('title_mrs')}
          </ToggleGroupItem>
          <ToggleGroupItem
            value="other"
            className={`flex-1 rounded-lg px-3 py-2 text-sm font-medium text-[#333333] hover:bg-[var(--selected-bg)] data-[state=on]:bg-[var(--selected-bg)] data-[state=on]:text-[#fdfdfd]`}>
            {t('title_other')}
          </ToggleGroupItem>
        </ToggleGroup>
        <div className="w-full space-y-2">
          <Input
            id="firstName"
            autoFocus={true}
            className="h-14 rounded-lg text-[#333333]"
            placeholder={t('firstName_placeholder')}
            value={state.firstName}
            onKeyDown={handleKeyDown}
            onChange={(e) => dispatch({ type: 'setFirstName', payload: e.target.value })}
          />
          <Input
            id="lastName"
            className="mb-2 h-14 rounded-lg text-[#333333]"
            placeholder={t('lastName_placeholder')}
            value={state.lastName}
            onKeyDown={handleKeyDown}
            onChange={(e) => dispatch({ type: 'setLastName', payload: e.target.value })}
          />
        </div>
      </div>
      <div className="mt-auto w-full pb-6">
        <Button
          style={{ color: textColor, backgroundColor: backgroundColor }}
          disabled={!state.title || !state.firstName || !state.lastName}
          className="h-14 w-full rounded-2xl text-center"
          onClick={() => next(state)}>
          {t('button_next')}
        </Button>
      </div>
    </div>
  )
}

function PhoneNumber({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const { t } = useTranslation()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && phoneNumber) {
      next({ phone: phoneNumber })
    }
  }
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-6 text-xl font-bold leading-5 text-[#333333]">
        {t('lead_widget_qs_phone_number')}
      </h2>
      <div>
        <Input
          autoFocus={true}
          type="tel"
          id="phone"
          className="h-14 rounded-lg text-[#333333]"
          placeholder="+49 567 123 4567"
          value={phoneNumber}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="mt-auto w-full pb-12">
        <Button
          style={{ color: textColor, backgroundColor: backgroundColor }}
          className="h-14 w-full rounded-2xl text-center"
          disabled={!phoneNumber}
          onClick={() => next({ phone: phoneNumber })}>
          {t('button_next')}
        </Button>
      </div>
    </div>
  )
}

function EmailInput({
  next,
  textColor,
  backgroundColor,
}: {
  next: (data: Record<string, any>) => void
  textColor: string
  backgroundColor: string
}) {
  const [email, setEmail] = useState<string>('')
  const { t } = useTranslation()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && email) {
      next({ email })
    }
  }
  return (
    <div className="flex h-full flex-col">
      <h2 className="my-6 text-xl font-bold text-[#333333]">
        {t('lead_widget_qs_email')}
      </h2>
      <div className="space-y-4">
        <div>
          <Label htmlFor="email" className="sr-only">
            {t('lead_widget_email_label')}
          </Label>
          <Input
            autoFocus={true}
            type="email"
            id="email"
            onKeyDown={handleKeyDown}
            className="h-14 rounded-lg text-[#333333]"
            placeholder="me@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="text-left text-xs text-[#333333]">{t('form_data_privacy')}</div>
        <div className="mt-auto w-full pb-12">
          <Button
            style={{ color: textColor, backgroundColor: backgroundColor }}
            className="h-14 w-full rounded-2xl text-center"
            disabled={!email}
            onClick={() => next({ email })}>
            {t('button_next')}
          </Button>
        </div>
      </div>
    </div>
  )
}

function ThankYou({
  backgroundColor,
  textColor,
}: {
  backgroundColor: string
  textColor: string
}) {
  const { t } = useTranslation()
  return (
    <div className="grid h-full w-full items-center justify-center">
      <div>
        <div className="my-6 flex flex-col items-center justify-center gap-4">
          <SquareCheck style={{ color: backgroundColor }} className="h-6 w-6" />
          <h2 className="text-xl font-bold leading-5 text-[#333333]">
            {t('lead_widget_thank_you')}
          </h2>
        </div>
        <p className="text-center text-[#8D8D8D]">
          {t('lead_widget_thank_you_description')}
        </p>
      </div>
    </div>
  )
}
