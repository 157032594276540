import { Link } from '@remix-run/react'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MailIcon,
  PhoneCallIcon,
  TwitterIcon,
  Youtube,
} from 'lucide-react'

export default function SocialIcons({
  phone,
  email,
  youtube,
  facebook,
  twitter,
  textColor,
  colorScheme,
  instagram,
  linkedin,
}: {
  phone?: string | null
  email?: string | null
  youtube?: string | null
  facebook?: string | null
  twitter?: string | null
  textColor?: string
  colorScheme: string[]
  instagram?: string | null
  linkedin?: string | null
}) {
  return (
    <div className="mx-auto flex flex-wrap items-center gap-2">
      {phone ? (
        <Link
          to={`tel:${phone}`}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <PhoneCallIcon className="h-6 w-6" />
        </Link>
      ) : null}
      {email ? (
        <Link
          to={`mailto:${email}`}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <MailIcon className="h-6 w-6" />
        </Link>
      ) : null}
      {youtube ? (
        <Link
          to={youtube}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <Youtube className="h-6 w-6" />
        </Link>
      ) : null}
      {facebook ? (
        <Link
          to={facebook}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <FacebookIcon className="h-6 w-6" />
        </Link>
      ) : null}
      {twitter ? (
        <Link
          to={twitter}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <TwitterIcon className="h-6 w-6" />
        </Link>
      ) : null}
      {instagram ? (
        <Link
          to={instagram}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <InstagramIcon className="h-6 w-6" />
        </Link>
      ) : null}
      {linkedin ? (
        <Link
          to={linkedin}
          style={{ color: textColor}}
          className="flex h-14 w-14 items-center justify-center rounded-full">
          <LinkedinIcon className="h-6 w-6" />
        </Link>
      ) : null}
    </div>
  )
}
