import { createMachine, assign } from 'xstate'
import posthog from 'posthog-js'

export type GlobalContext = {
  data: Record<string, any>
  progress: number
}

const isResidential = (context: GlobalContext) =>
  ['house', 'apartment'].includes(context.data.propertyType)

export const machine = createMachine(
  {
    id: 'lead generation capture form',
    context: { data: {}, progress: 0 } as GlobalContext,
    initial: 'property_type',
    states: {
      property_type: {
        exit: [() => posthog.capture('funnel_start')],
        on: {
          PLOT_SELECTED: {
            target: 'size',
            actions: assign({
              data: ({ context }) => ({ ...context.data, propertyType: 'plot' }),
              progress: 5,
            }),
          },
          HOUSE_SELECTED: {
            target: 'current_usage',
            actions: assign({
              data: ({ context }) => ({ ...context.data, propertyType: 'house' }),
            }),
          },
          APARTMENT_SELECTED: {
            target: 'current_usage',
            actions: assign({
              data: ({ context }) => ({ ...context.data, propertyType: 'apartment' }),
            }),
          },
          COMMERCIAL_SELECTED: {
            target: 'current_usage',
            actions: assign({
              data: ({ context }) => ({
                ...context.data,
                propertyType: 'commercial_space',
              }),
            }),
          },
        },
      },
      current_usage: {
        on: {
          NEXT: {
            target: 'quality',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'property_type',
        },
      },
      quality: {
        on: {
          NEXT: {
            target: 'room_size',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'current_usage',
        },
      },
      room_size: {
        on: {
          NEXT: {
            target: 'size',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'quality',
        },
      },
      size: {
        on: {
          NEXT: {
            target: 'build_year',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: [
            {
              target: 'room_size',
              guard: { type: 'isResidential' },
            },
            {
              target: 'quality',
            },
          ],
        },
      },
      build_year: {
        on: {
          NEXT: {
            target: 'postal_code',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'size',
        },
      },
      postal_code: {
        on: {
          NEXT: {
            target: 'transaction',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'build_year',
        },
      },
      transaction: {
        on: {
          NEXT: {
            target: 'timeline',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'postal_code',
        },
      },
      timeline: {
        on: {
          NEXT: {
            target: 'personal_details',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'transaction',
        },
      },
      personal_details: {
        on: {
          NEXT: {
            target: 'phone_number',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'timeline',
        },
      },
      phone_number: {
        on: {
          NEXT: {
            target: 'email',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'personal_details',
        },
      },
      email: {
        on: {
          NEXT: {
            target: 'submitting',
            actions: 'updateDataAndProgress',
          },
          PREVIOUS: 'phone_number',
        },
      },
      submitting: {
        invoke: {
          src: 'submitFormData',
          input: ({ context }) => ({ data: context.data }),
          onDone: {
            target: 'data_submitted',
            actions: assign({ progress: 100 }),
          },
          onError: {
            target: 'submission_error',
            actions: assign({ progress: 95 }),
          },
        },
      },
      submission_error: {
        on: {
          NEXT: 'submitting',
        },
      },
      data_submitted: {
        entry: [({}) => posthog.capture('funnel_complete')],
        type: 'final',
      },
    },
  },
  {
    guards: {
      isResidential: ({ context }) => isResidential(context),
    },
    actions: {
      updateDataAndProgress: assign({
        progress: ({ context }) => {
          const totalSteps = 12 // Now we have a fixed number of steps
          return Math.min(context.progress + 100 / totalSteps, 100)
        },
        data: ({ context, event }) => {
          if (event.type === 'NEXT') {
            return { ...context.data, ...event.data }
          }
          return context.data
        },
      }),
    },
  },
)
