import { MapPinIcon } from '@heroicons/react/20/solid'

export default function UserLocation({
  city,
  textColor,
}: {
  city: string | null
  colorScheme?: string[]
  textColor?: string
}) {
  if (!city) return null
  return (
    <div
      style={{ color: textColor }}
      className="mx-auto mb-6 mt-3 flex items-center justify-center gap-1 rounded-md">
      <MapPinIcon className="h-6 w-6" />
      <h4 className="mt-1 text-lg font-bold">{city}</h4>
    </div>
  )
}
