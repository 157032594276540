import { Button } from '#app/components/ui/button'
import { Progress } from '#app/components/ui/progress'
import type { machine } from '#app/machines/machine'
import { ChevronLeft } from 'lucide-react'
import type { EventFrom, StateFrom } from 'xstate'
import { useTranslation } from 'react-i18next'

export type FunnelMachineType = typeof machine
type FunnelMachineState = StateFrom<FunnelMachineType>
type FunnelMachineEvent = EventFrom<FunnelMachineType>

type ProgressNavigationProps = {
  state: FunnelMachineState
  send: (event: FunnelMachineEvent) => void
  backgroundColor: string
}

export default function ProgressNavigation({
  state,
  send,
  backgroundColor,
}: ProgressNavigationProps) {
  const { t } = useTranslation()
  if (state.matches('property_type')) {
    return (
      <h2 className="mb-6 text-sm font-bold text-[#8D8D8D]">
        {t('public_profile_property_valuation_header')}
      </h2>
    )
  }

  return (
    <div className="mx-auto flex items-center justify-center gap-2">
      {state.value !== 'data_submitted' && (
        <Button
          style={{ color: backgroundColor }}
          className="px-0"
          variant="ghost"
          onClick={() => send({ type: 'PREVIOUS' })}>
          <ChevronLeft className="h-6 w-6" />
        </Button>
      )}
      <Progress
        value={state.context.progress}
        backgroundColor={backgroundColor}
        className="h-2 flex-grow pr-2"
      />
    </div>
  )
}
