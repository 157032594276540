import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '#app/utils/misc.ts'
interface SliderProps
  extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn('relative flex w-full touch-none select-none items-center', className)}
    {...props}>
    <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-[#8d8d8d]">
      <SliderPrimitive.Range className="absolute h-full bg-[#333333]" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block h-10 w-10 rounded-full border border-[#FDFDFD] bg-[#333333] text-[#333333] ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 md:h-6 md:w-6">
      <span className="absolute left-2 top-10 rounded px-2 text-lg sm:left-0 sm:top-6 sm:text-sm">
        {props?.value?.[0]}
      </span>
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
