import React from 'react'
import { Button as ShadcnButton } from '#app/components/ui/button'

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outline' | 'default' | 'secondary' | 'ghost' | 'link' | 'destructive'
  size?: 'default' | 'sm' | 'lg' | 'icon'
  textColor?: string
  backgroundColor?: string
  onClick?: () => void
  children: React.ReactNode
}

const MultiChoiceButton: React.FC<CustomButtonProps> = ({
  variant = 'outline',
  size = 'icon',
  className = '',
  onClick,
  children,
  ...props
}) => {
  return (
    <ShadcnButton
      variant={variant}
      size={size}
      className={`h-14 w-full rounded-xl border-[#DFE0E1] text-[#333333] text-center font-medium ${className}`}
      onClick={onClick}
      {...props}>
      {children}
    </ShadcnButton>
  )
}

export default MultiChoiceButton
